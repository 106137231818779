import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { DEFAULT_COMPARISON_RANK } from "@/game-lol/constants/coaching-constants.mjs";
import {
  EVENT_LOL_TIMELINE_LOADED,
  QUEUE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import lolFetchPostmatchDataRefs from "@/game-lol/fetches/lol-fetch-postmatch-data.refs.mjs";
import LoLChampionStats from "@/game-lol/models/lol-champion-stats.mjs";
import LolMatch from "@/game-lol/models/lol-match.mjs";
import LolMatchTimeline from "@/game-lol/models/lol-match-timeline.mjs";
import LolStatsByMinute from "@/game-lol/models/lol-stats-by-minute.mjs";
import LoLSummoner from "@/game-lol/models/lol-summoner.mjs";
import * as API from "@/game-lol/utils/api.mjs";
import { coachableQueue } from "@/game-lol/utils/coaching-utils.mjs";
import isLinkedSummoner from "@/game-lol/utils/is-linked-summoner.mjs";
import { findPlayer, matchStatePath } from "@/game-lol/utils/match-utils.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import {
  coachingStatsKey,
  coachingStatsVariables,
  getDerivedId,
  isARAMQueue,
} from "@/game-lol/utils/util.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import { devError } from "@/util/dev.mjs";
import retry from "@/util/retry-promise.mjs";

const timelineSent = {};

async function fetchData(
  [region, name, matchId, tab, tabId],
  _searchParams,
  state,
) {
  const isPBE = region?.toLowerCase() === "pbe1";
  if (isPBE) return;

  const derivedId = getDerivedId(region, name);
  const isOwnProfile = isLinkedSummoner(readState, region, name);

  // Await profile immediately, its required to find player info in the match
  // You can test this by running the integration test without this awaited
  // The test will fail to find the player and analyze the results of the match
  const localPlayerProfile = await getData(
    API.getSummonerWithoutRanks({
      region,
      name,
    }),
    LoLSummoner,
    ["lol", "profiles", derivedId],
    {
      mergeFn: deepMerge,
      shouldFetchIfPathExists: !state?.isUpdate,
    },
  );
  const match = await getData(
    API.getMatch(region, Number(matchId)),
    LolMatch,
    matchStatePath(region, matchId),
    // May want to do this and just rely on LCU, has advantages/disadvantages
    // We've been having API key issues lately, this would likely help a good bit
    // { shouldFetchIfPathExists: false },
  );

  if (!match) return;

  const matchQueueSymbol = QueueSymbol(match.queueId);
  const isARAM = isARAMQueue(matchQueueSymbol);

  // Should fetch additional requests like timeline, coaching data, etc.
  // Non-coachable matches dont need to request all this additional data
  const participants = match.participants || [];
  const localPlayer = findPlayer(localPlayerProfile, match);
  const localPlayerRole = localPlayer?.teamPosition;
  const localPlayerChampionId = localPlayer?.championId;

  const participantsReq = participants
    .filter((p) => {
      // Dont fetch local player profile, we already did that above
      const samePUUID = p.puuid === localPlayerProfile?.puuid;
      const sameSummonerName =
        p.summonerName === localPlayerProfile?.summonerName;
      const sameRiotID =
        p.riotIdGameName === localPlayerProfile?.riotAccount?.gameName &&
        p.riotIdTagline === localPlayerProfile?.riotAccount?.tagLine;

      if (samePUUID || sameSummonerName || sameRiotID) return false;

      return true;
    })
    .map((p) => {
      return getData(
        API.getSummonerWithoutRanks({
          region,
          puuid: p.puuid,
          name: p.summonerName,
          gameName: p.riotIdGameName,
          tagLine: p.riotIdTagline,
        }),
        LoLSummoner,
        [
          "lol",
          "profiles",
          getDerivedId(
            region,
            p.summonerName,
            p.riotIdGameName,
            p.riotIdTagline,
          ),
        ],
        {
          mergeFn: deepMerge,
          shouldFetchIfPathExists: false, // dont re-fetch if info was already stored from in-game
        },
      ).catch((error) => {
        devError("FAILED TO SIDE-LOAD LEAGUE PROFILE", error);
      });
    });

  const isCoachable = coachableQueue(match) && localPlayerChampionId;

  // Timeline
  if (!isPBE) {
    const timelineStart = performance.now();
    retry(
      () =>
        getData(
          API.getMatchTimeline(region, matchId),
          LolMatchTimeline,
          ["lol", "matchTimeline", matchId],
          {
            shouldFetchIfPathExists: false,
          },
        ),
      { maxRetries: 3, interval: 1000 },
    )
      .then((timeline) => {
        if (state.isProgrammatic && !timelineSent[matchId]) {
          const timelineEnd = performance.now();
          const timeTaken = (timelineEnd - timelineStart) / 1000; // seconds
          eventBus.emit(EVENT_LOL_TIMELINE_LOADED, {
            hasTimeline: (timeline?.frames?.length ?? 0) > 0,
            timeTaken,
            region,
            matchId,
          });
          timelineSent[matchId] = true;
        }
      })
      .catch(devError);
  }

  if (isCoachable) {
    const localPlayerRank =
      (localPlayerProfile?.latestRanks || []).find(
        (q) => q.queue === QUEUE_SYMBOLS.rankedSoloDuo,
      )?.tier || DEFAULT_COMPARISON_RANK;

    if (isARAM) {
      const statsVariables = coachingStatsVariables({
        championId: localPlayerChampionId,
        queue: QUEUE_SYMBOLS.aram,
      });
      getData(
        API.getChampionStats(localPlayerChampionId, statsVariables, false),
        LoLChampionStats,
        ["lol", "championStats", coachingStatsKey(statsVariables)],
      );
    } else {
      const statsVariables = coachingStatsVariables({
        championId: localPlayerChampionId,
        queue: QUEUE_SYMBOLS.rankedSoloDuo,
        tier: localPlayerRank,
        role: localPlayerRole,
      });
      getData(
        API.getStatsByMinute(statsVariables),
        LolStatsByMinute,
        ["lol", "championStatsByMin", coachingStatsKey(statsVariables)],
        { shouldFetchIfPathExists: false },
      ).catch((error) => {
        devError("FAILED TO SIDE-LOAD DIVISION STATS", error);
      });
    }
  }

  const additional = lolFetchPostmatchDataRefs.fetchFunctions.map((fn) =>
    fn({ gameId: matchId, isOwnProfile, tab, tabId }),
  );

  return Promise.all([participantsReq, ...additional]);
}

export default fetchData;
