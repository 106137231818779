import createModel, { Silent } from "@/__main__/data-model.mjs";
import { RankSymbol } from "@/game-lol/utils/symbol-rank.mjs";

const ValueObject = {
  mean: Number,
  stdev: Number,
};

export const model = {
  championId: Number,
  tier: RankSymbol,
  ccPerMinute: ValueObject,
  creepScoreByMinute: [ValueObject],
  totalGoldByMinute: [ValueObject],
  goldSpentByMinute: [ValueObject],
  goldSpentAdvantageByMinute: [ValueObject],
  totalDamageDoneToChampionsByMinute: [ValueObject],
  totalDamageTakenByMinute: [ValueObject],
  killsByMinute: [ValueObject],
  deathsByMinute: [ValueObject],
  assistsByMinute: [ValueObject],
  killParticipationByMinute: [ValueObject],
  kdaByMinute: [ValueObject],
  levelByMinute: [ValueObject],
  levelAdvantageByMinute: [ValueObject],
  xpByMinute: [ValueObject],
  visionScoreByMinute: [ValueObject],
  controlWardsPlacedByMinute: [ValueObject],
  sightWardsPlacedByMinute: [ValueObject],
  yellowTrinketWardsPlacedByMinute: [ValueObject],
  wardsKilledByMinute: [ValueObject],
  timeEnemySpentControlledByMinute: [ValueObject],
  spell1CastsPerMinute: ValueObject,
  spell2CastsPerMinute: ValueObject,
  spell3CastsPerMinute: ValueObject,
  spell4CastsPerMinute: ValueObject,
};

const apiModelValidation = createModel({
  data: {
    divisionStats: [Silent(model)],
  },
});

function transform(data) {
  data = apiModelValidation(data);

  return data.data;
}

export default transform;
